//blog-treding
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

//modals
.iq-modal {
  > .modal-dialog {
    max-width: 1400px;
  }
}

//file-manager dropzone
.file-manager-modal {
  .dzu-dropzone {
    min-height: 500px;
    overflow: hidden;
    border: 1px solid #8a92a6;
  }
}

.dark {
  .file-manager-modal {
    .dzu-dropzone {
      background-color: #151824;
    }
  }
}

.btn-upload {
  input[type="file"]::file-selector-button {
    background-color: var(--bs-primary);
    border-radius: 0.25rem;
    box-shadow: none;
    color: white;
    border: 1px solid var(--bs-primary);
  }

  input[type="file"]::file-selector-button:hover {
    background-color: rgba(var(--bs-primary), 0.9);
  }
}

.icon-button,
.icon-button:hover {
  background-color: none !important;
  border: none !important;
  color: #7d879c !important;
  background: none !important;
  padding: 8px 20px !important;
  width: 25px;
  font-size: 20px;
}

.product-check .form-check-input {
  width: 44px;
  height: 21px;
}

//dark choices

.dark .choices[data-type*="select-one"] {
  .choices__input {
    background: #222738;
    border-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.dark .is-open .choices__list[aria-expanded] {
  border-color: rgba(#ffff, 0.1);
}

.productlist {
  margin-bottom: 16px;
  margin-top: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
}

.add-product-button,
.add-product-button:hover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  border-radius: 8px;
  height: 37px;
  background-color: green !important;
  border: none;
  color: white;
  padding: 22px 15px !important;
}

.import-product-button,
.import-product-button:hover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  border-radius: 8px;
  height: 37px;
  background-color: green !important;
  border: none;
  color: white;
  padding: 22px 15px !important;
}

.product-search {
  width: 350px;
  height: 44px;
}

.product-svg {
  color: white !important;
  font-size: 20px;
}

.category-product {
  font-size: 13px;
  padding: 3px 12px;
  border-radius: 16px;
  display: inline-block;
  color: rgb(43, 52, 69);
  background-color: #f3f5f9;
  font-weight: 500;
}

.product-image {
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 200px;
  border-radius: 10px;
  border: 1.5px dashed rgb(227, 233, 239);

  text-align: center;
  background-color: rgb(246, 249, 252);
  margin-top: 20px;
}

.dragTypo {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(125, 135, 156);
}

.or {
  font-size: 12px;
  color: #dae1e7;
  padding-left: 8px;
  padding-right: 8px;
  margin: 0px;
}

.selectFiles {
  color: #07aa4d !important;
  border: 1px solid #07aa4d !important;
  font-weight: 600;
  border-radius: 8px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
  background: none !important;
}

.uploadTypo {
  font-size: 12px;
  color: #7d879c;
}

.customer-name {
  font-size: 14px;
  font-weight: 600;
  color: rgb(43, 52, 69);
}

.customer-comment {
  font-size: 12px;
  color: rgb(43, 52, 69);
}

.save-product {
  @extend .add-product-button;
  justify-content: center;
}

.level {
  font-size: 14px;
  font-weight: 600;
  color: rgb(43, 52, 69);
}

.order-status {
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  padding: 3px 12px;
  text-align: center;
}

.order-input {
  border: 1px solid rgba(43, 52, 69, 0.15);
  width: 64px;
  height: 37px;
  padding: 0px 10px;
  border-radius: 6px;
}

.order-property {
  color: rgb(125, 135, 156);
  font-size: 14px;
  margin-bottom: 0px;
}

.order-subtotal {
  color: rgb(125, 135, 156);
  font-size: 14px;
  margin: 0;
}

.order-value {
  @extend .order-input;
  width: 84px;
  border-radius: 8px;
}

.web-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(86, 221, 221, 0.08) !important;
  border: none !important;
  padding: 10px 17px;
  border-radius: 8px;
  margin-left: 50px;
}

.header-search {
  @extend .product-search;
  background: #d3e8e84a;
  border-radius: 8px;
  width: 200px;
}

.bell-button,
.bell-button:hover {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  position: relative;
}

.bell-span {
  position: absolute;
  border-radius: 50%;
  top: 14%;
  right: -20%;
}

.update-button,
.update-button:hover {
  background: rgb(0, 136, 255) !important;
  font-weight: 600 !important;
  border-radius: 8px;
}

.header-width {
  width: -webkit-fill-available;
  width: -moz-available;
  z-index: 9;
}

.show-modal > div {
  position: absolute;
  top: 0%;
  right: 6%;
}

.content-inner > div {
  margin-top: 80px;
}

.page-link {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px !important;
  padding: 0px;
  border-color: #4e97fd !important;

  // &.active {
  //
  //

  // }
  & svg {
    color: #4e97fd !important;
  }
}

.page-item .page-link-pagination {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px !important;
  padding: 0px;
  border: 1px solid #4e97fd;
  color: #4e97fd !important;
}

.morning-title {
  margin-bottom: 4px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #07aa4d;
  text-transform: none;
  white-space: normal;
}

.subtitle {
  font-size: 14px;
  color: rgb(125, 135, 156);
}

.date-select {
  width: 110px;
  border: none;
}

.sales {
  background: #07aa4d !important;
  color: #07aa4d;
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 50px;
  margin-right: 3px;
}

.expense {
  background: rgb(125, 135, 156) !important;
  color: rgb(125, 135, 156);
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 50px;
  margin-right: 3px;
}

.product-share .recharts-wrapper {
  width: 360px !important;
  height: 100%;
  right: 29px;
  top: -99px;
}

.product-share .recharts-wrapper > .recharts-surface {
  width: 50% !important;
  height: 260px !important;
}

/* total order */
.total-order .recharts-wrapper {
  top: 10px !important;
  width: 100% !important;
}

.total-order .recharts-wrapper > .recharts-surface {
  width: 100% !important;
}

.market-share .recharts-wrapper {
  width: 100% !important;
  height: 100px !important;
  top: -14px !important;
  right: -16px !important;
}

.market-share .recharts-wrapper > .recharts-surface {
  width: 150px !important;
  height: 100px !important;
}

.recharts-cartesian-grid-vertical {
  display: none;
}

.recharts-cartesian-axis-line {
  display: none;
}

.recharts-default-legend {
  display: none;
}

.success-tick {
  background: rgb(231, 249, 237);
  color: rgb(51, 208, 103);
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px;
}

.pending-tick {
  background: rgb(255, 234, 234);
  color: rgb(233, 69, 96);
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px;
}

.order-button,
.order-button:hover {
  background: none !important;
  color: #07aa4d !important;
  border-color: #07aa4d !important;
  border-radius: 10px !important;
}

.refund-delete {
  width: 35px;
  height: 35px;
  background: #e4e4f5 !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  cursor: pointer;
}

.refund-svg {
  color: gray;
}

.quantity {
  width: 50px;
  padding: 7px 7px;
}

.quantity-button {
  background-color: transparent !important;
  border: none !important;
  color: black !important;
}

.basic-button,
.basic-button:hover {
  width: 150px;
  color: black !important;
  font-weight: bold;
  background: white !important;
  border-color: #b8acac38 !important;
  border-radius: 0px !important;
}

.style-button,
.style-button:hover {
  @extend .basic-button;
  border-radius: 20px !important;
  width: 120px !important;
  margin-right: 10px;
}

.color-button,
.color-button:hover {
  width: 25px !important;
  height: 25px;
  border-radius: 30px !important;
  margin-right: 25px;
  padding: 0px;
  border: none !important;
}

.tabs ul {
  border-bottom: 1px solid #b8b6b64d;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.tabs ul li button {
  color: black !important;
  font-size: 15px;
  font-weight: bold;
}

.tabs ul li .nav-link.active {
  color: black !important;
  background-color: white !important;
  border-color: transparent !important;
  border-bottom: 1px solid blue !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 10px;
}

.addOn-button,
.addOn-button:hover {
  background: white !important;
  color: black !important;
  padding: 0px !important;
  width: 30px !important;
  height: 30px !important;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-to-cart-button,
.add-to-cart-button:hover {
  background: #0d0daa !important;
  width: 170px;
}
.product-detail-card {
  position: sticky;
  bottom: 5%;
  width: 85%;
  z-index: 1;
  left: 70px;
  padding: 13px;
  // box-shadow: 0px 0px 10px 0px #0000001f;
}

.returned-request-button {
  background: none !important;
  border: none !important;
  color: red !important;
  font-size: 18px !important;
}
